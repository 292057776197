.destination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: solid 2px #F5F5F5;
    padding-top: 25px;
    margin-top: 25px;

    &:first-child {
        padding: 0;
        margin: 0;
        border: none;
    }

    &--thumbnail {
        width: 25%;
        height: auto;
    }

    &--content {
        width: 70%;
    }

    &--location {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            font-size: 13px;
        }

        &-icon img {
            width: 7px;
        }

        &-name {
            text-transform: uppercase;
            color: #2B283A;
            letter-spacing: 2px;;
            margin: 0 15px 0 5px;
        }

        &-maps a {
            color: #918E9B;
        }
    }

    &--title {
        color: #2B283A;
        font-size: 25px;
        font-weight: 600;
        margin: 10px 0 0;
    }

    &--dates {
        margin: 15px 0 0;
        font-size: 10.5px;
        font-weight: 600;
    }

    &--description {
        margin: 10px 0 0;
        font-size: 10.5px;
        line-height: 1.6em;
    }
}