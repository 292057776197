.header {
    background: #F55A5A;
    overflow: hidden;
    padding: 15px 0;
    
    &--inner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--icon {
        width: 24px;
    }

    &--strapline {
        color: #fff;
        font-size: 14px;
        margin: 0 0 0 8px;
    }
}