/** Base styles and defaults **/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

body {
	font-family: 'Inter', sans-serif;
	font-size: 16px;
	line-height: 1.4em;
}

.container {
	width: 575px;
	max-width: 100%;
	padding: 0 15px;
	margin: 0 auto;
}
